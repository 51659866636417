/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-richtext': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 4.5V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h5.5zm-3 0A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V4.5z"/><path pid="1" d="M4.5 12.5A.5.5 0 015 12h3a.5.5 0 010 1H5a.5.5 0 01-.5-.5m0-2A.5.5 0 015 10h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5m1.639-3.708l1.33.886 1.854-1.855a.25.25 0 01.289-.047l1.888.974V8.5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V8s1.54-1.274 1.639-1.208M6.25 6a.75.75 0 100-1.5.75.75 0 000 1.5"/>',
    },
});
